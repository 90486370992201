/* CMP GMAP (Ver 0.2) */

var CMP = CMP || {};

(function ($) {
    "use strict";

    CMP.gmap = {
        _map: null,
        _options: null,
        _markers: [],
        _getMapStyle: function () {
            
            // Default map style (personalizzato, non google)
            return [
                {
                    "featureType": "administrative",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#eaeaea"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#808080"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#414141"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ];
            
        },

        init: function (strSelector, options) {
            var self = this;
            self._map = self._options = null;
            self._markers = [];

            strSelector = strSelector || '.cmp-gmap';
            var $map = $(strSelector);

            if ($map && $map.length > 1) {
                $map = $map.first();
                log('GMap: Questo componente funziona solo a singolo elemento');
            }

            var defaults = {
                center: new google.maps.LatLng(40.8563100, 14.2464100),
                zoom: 17,
                disableDefaultUI: true,
                scrollwheel: false,
                disableDoubleClickZoom: true,
                draggable: true,
                // Custom Icon
                customIcon: null,
                customIconHover: null,
                onIconClick: $.noop,
                styles: self._getMapStyle()
            };

            self._options = $.extend(defaults, options);


            if (typeof (google) !== 'undefined' && $map) {
                self._map = new google.maps.Map($map[0], self._options);
            }

            return $.extend(true, {}, self);
            //return self;
        },
        addMarker: function (coords) {
            var self = this;

            if (self._map && typeof (google) !== 'undefined') {
                coords = coords || self._options.center;

                var _markerOptions = {
                    position: coords,
                    map: self._map
                };

                // HOW TO INIT A CUSTOM ICON
                // new google.maps.MarkerImage('static/images/sprite.png', new google.maps.Size(26, 26), new google.maps.Point(100, 56));
                // 'http://mywebsite.it/static/images/myCustomIcon.png'
                if (self._options.customIcon && (typeof (self._options.customIcon) === 'function' || typeof (self._options.customIcon) === 'string')) {
                    _markerOptions.icon = self._options.customIcon;
                }

                var marker = new google.maps.Marker(_markerOptions);
                self._markers.push(marker);

                google.maps.event.addListener(marker, 'click', function () {

                    var hasCustomIcon = self._options.customIcon ? true : false;
                    var hasCustomIconHover = self._options.customIconHover ? true : false;

                    // Change Image after click
                    if (hasCustomIcon)
                    {
                        for (var i = 0; i < self._markers.length; i++) {
                            self._markers[i].setIcon(self._options.customIcon);
                        }
                    }
                    
                    if (hasCustomIconHover)
                    {
                        marker.setIcon(self._options.customIconHover);
                    }                        

                    if (self._options.onIconClick && $.isFunction(self._options.onIconClick)) {
                        self._options.onIconClick();
                    }

                });
            }

            return self;
        },
        refresh: function () {
            var self = this;

            var currCenter = self._map.getCenter();
            google.maps.event.trigger(self._map, 'resize');
            self._map.setCenter(currCenter);

            return self;
        }        
    };

})(jQuery)