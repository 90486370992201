/* CMP MODAL (Ver 0.1) */

var CMP = CMP || {};

(function ($) {
    "use strict";

    CMP.modal = {
        alert: function (msg) {
            var $modal = $('.cmp-modal-alert');
            $modal.find('.modal-body .text-content').html(msg);
            $modal.modal();
        },
        error: function (msg) {
            var $modal = $('.cmp-modal-error');
            $modal.find('.modal-body .text-content').html(msg);
            $modal.modal();
        },
        info: function (msg) {
            var $modal = $('.cmp-modal-info');
            $modal.find('.modal-body .text-content').html(msg);
            $modal.modal();
        },
        confirm: function (msg, onYes, onNo) {
            var $modal = $('.cmp-modal-confirm');
            $modal.find('.modal-body .text-content').html(msg);

            onNo = onNo || $.noop;
            $modal.find('.modal-footer .no').unbind('click').on('click', function (e) {
                e.stopPropagation();
                e.preventDefault();
                onNo();
                $modal.modal('hide');
            });

            onYes = onYes || $.noop;
            $modal.find('.modal-footer .yes').unbind('click').on('click', function (e) {
                e.stopPropagation();
                e.preventDefault();
                onYes();
                $modal.modal('hide');
            });
            $modal.modal();
        }
    };

})(jQuery);

