/* CMP IMAGE PLACEHOLDER (Ver 0.1) */

var CMP = CMP || {};

(function ($) {
    "use strict";

    CMP.lazy_images = function (strSelector, options) {
        if ($.fn.unveil) {
            
            options = options || {};
            var defaults = {
                threshold:0, // Iniziare a caricare l'img N PX prima
                callback: null
            };
            options = $.extend(defaults, options);

            $(strSelector).unveil(options.threshold, options.callback);
        }
    }
    
})(jQuery);