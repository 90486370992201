/* CMP SOCIAL SHARE (Ver 0.1) */

(function ($) {
    "use strict";

    $('.cmp-social-share .cmp-socialshare-dropdown li').click(function () {
        var $this = $(this);
        if ($this.attr('data-social')) {
            switch($this.attr('data-social'))
            {
                case 'facebook':
                    shareFacebook(this);
                    break;

                case 'twitter':
                    shareTwitter(this);
                    break;

                case 'google-plus':
                    shareGPlus(this);
                    break;

                case 'pinterest':
                    sharePinterest(this);
                    break;

                case 'linkedin':
                    shareLinkedin(this);
                    break;

                case 'email':
                    shareEmail(this);
                    break;
            }
        }
    });

})(jQuery);


/*
 * FACEBOOK SHARE HTML ATTRIBUTES:
 * data-link 
 */
function shareFacebook(el) {

    var $this = $(el);
    
    var link = $this.attr('data-link');
    if (link == "") { link = window.location.href; }
    
    var URL = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(link);
    window.open(URL, "_blank");
}

/*
 * TWITTER SHARE HTML ATTRIBUTES:
 * data-title
 * data-link
 * data-hashtags
 * data-via
 */
function shareTwitter(el) {

    var $this = $(el);

    var title = $this.attr('data-title');
    var link = $this.attr('data-link');
    if (link == "") { link = window.location.href; }
    var hashTag = $this.attr('data-hashtags');
    var via = $this.attr('data-via');

    var URL = "https://www.twitter.com/share?url=" + encodeURIComponent(link) + "&hashtags=" + encodeURIComponent(hashTag) + "&via=" + encodeURIComponent(via) + "&text=" + encodeURIComponent(title);
    window.open(URL, "_blank");
}

/*
 * PINTEREST SHARE HTML ATTRIBUTES:
 * data-title
 * data-link
 * data-picture
 */
function sharePinterest(el) {

    var $this = $(el);

    var link = $this.attr('data-link');
    if (link == "") { link = window.location.href; }
    var picture = $this.attr('data-picture');
    var title = $this.attr('data-title');

    var URL = "http://pinterest.com/pin/create/button/?url=" + encodeURIComponent(link) + "&media=" + encodeURIComponent(picture) + "&description=" + encodeURIComponent(title);
    window.open(URL, "_blank");
}

/*
 * GOOGLE PLUS SHARE HTML ATTRIBUTES:
 * data-link
 */
function shareGPlus(el) {

    var $this = $(el);    
    var link = $this.attr('data-link');
    if (link == "") { link = window.location.href; }

    var URL = 'https://plus.google.com/share?url=' + link;
    window.open(URL, '_blank');    
}

/*
 * EMAIL SHARE HTML ATTRIBUTES:
 * data-subject
 * data-link
 */
function shareEmail(el) {

    var $this = $(el);
    
    var link = $this.attr('data-link');
    if (link == "") { link = window.location.href; }
    var subject = $this.attr('data-subject');

    window.open('mailto:' + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(link), '_blank');
}

/*
 * LINKEDIN SHARE HTML ATTRIBUTES:
 * data-title
 * data-link
 */
function shareLinkedin(el) {
    
    var $this = $(el);
    var title = $this.attr('data-title');
    var link = $this.attr('data-link');
    if (link == "") { link = window.location.href; }

    var URL = 'http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(link) + '&title=' + encodeURIComponent(title);
    window.open(URL, '_blank');
}