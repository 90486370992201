/* CMP PAGELOADER (Ver. 0.1) */

var CMP = CMP || {};

(function ($) {
    "use strict";
    
    CMP.pageloader = {
        setVisibility: function (show) {
            var $loader = $('.cmp-page-loader');
            if (!$loader)
                return;

            if (show) {
                $loader.fadeIn().css('display','table');
            }
            else {
                $loader.fadeOut();
            }
        }
    };

})(jQuery);