/* CMP UTILS (Ver. 0.2) */

// Base Browse detect function (chrome, ie, firefox, safari, opera)
// https://gist.github.com/iwanbk/5906833
// USAGE: BrowserDetect.browser == 'Explorer' && BrowserDetect.version <= 9
var BrowserDetect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "Other";
        this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
    },
    searchString: function (data) {
        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            this.versionSearchString = data[i].subString;

            if (dataString.indexOf(data[i].subString) !== -1) {
                return data[i].identity;
            }
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index === -1) {
            return;
        }

        var rv = dataString.indexOf("rv:");
        if (this.versionSearchString === "Trident" && rv !== -1) {
            return parseFloat(dataString.substring(rv + 3));
        } else {
            return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
        }
    },
    dataBrowser: [
        { string: navigator.userAgent, subString: "Chrome", identity: "Chrome" },
        { string: navigator.userAgent, subString: "MSIE", identity: "Explorer" },
        { string: navigator.userAgent, subString: "Trident", identity: "Explorer" },
        { string: navigator.userAgent, subString: "Firefox", identity: "Firefox" },
        { string: navigator.userAgent, subString: "Safari", identity: "Safari" },
        { string: navigator.userAgent, subString: "Opera", identity: "Opera" }
    ]
};
BrowserDetect.init();

/* Mobile detection by User Agent */
var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPod|iPad/i);
    },
    iPhone: function () {
        return navigator.userAgent.match(/iPhone|iPod/i);
    },
    iPad: function () {
        return navigator.userAgent.match(/iPad/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.iPad() || isMobile.Opera() || isMobile.Windows());
    },
    any_smartphones: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    },
    any_tablets: function () {
        return (isMobile.iPad());
    }
};

// Get IOS version
function getIOSVersion() {
    return parseFloat(
        ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
        .replace('undefined', '3_2').replace('_', '.').replace('_', '')
        ) || false;
}

// Check for touch devices 
function isTouch() {
    return 'ontouchstart' in document.documentElement;
}

// Console log
var DEBUG = true;
function log(msg) {
    if (window.console && DEBUG) {
        console.log(msg);
    }
}

// Track GA Event
function GATrackEvent(data1, data2, data3) {

    log('Tracked event: ' + data1 + ' - ' + data2 + ' - ' + data3);
    // Di base: ga('send', 'event', ....)
    if (document.location.host != 'localhost') {
        ga('send', 'event', data1, data2, data3);
    }
}

// Refresh bootstrap select
function refreshBootStrapSelect(selector) {
    log('BootStrap Select refreshed');
    $(selector + ' .selectpicker').selectpicker('refresh');
}

// Validate with regex
function isEmailValid(str) {
    var _regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/i
    return _regexEmail.test(str);
}
function isUrlValid(str) {
    var _regexWebsite = /^(http(?:s)?\:\/\/[a-zA-Z0-9]+(?:(?:\.|\-)[a-zA-Z0-9]+)+(?:\:\d+)?(?:\/[\w\-]+)*(?:\/?|\/\w+\.[a-zA-Z]{2,4}(?:\?[\w]+\=[\w\-]+)?)?(?:\&[\w]+\=[\w\-]+)*)$/
    return _regexWebsite.test(str);
}

// cross browser window width
function getWindowWidth() {
    var w = 0;
    // IE
    if (typeof (window.innerWidth) != 'number') {

        if (!(document.documentElement.clientWidth === 0)) {
            // strict mode
            w = document.documentElement.clientWidth;
        } else {
            // quirks mode
            w = document.body.clientWidth;
        }
    } else {
        // w3c
        w = window.innerWidth;
    }
    return w;
};

function maskCurrecy() {
    if ($.fn.inputmask) {
        $(".mask-currency").inputmask("decimal", {
            radixPoint: ",",
            groupSeparator: ".",
            digits: 2,
            autoGroup: true,
            suffix: '€',
            rightAlign: false
        });
    }
}
maskCurrecy();

function maskItaDate() {
    if ($.fn.inputmask) {
        $(".mask-itadate").inputmask("d/m/y", { "placeholder": "dd/mm/yyyy" });
    }
}
maskItaDate();

function maskHexColor() {
    if ($.fn.inputmask) {
        $.extend($.inputmask.defaults.definitions, {
            'h': {
                validator: "[A-Fa-f0-9]",
                cardinality: 1,
                casing: "upper" //auto uppercasing
            },
            '#': {
                validator: "#",
            }
        });
        $(".mask-hexcolor").inputmask("#hhhhhh", {placeholder: '#      '});
    }
}
maskHexColor();

function maskTimePicker() {
    if ($.fn.inputmask) {
        $(".mask-time").inputmask("hh:mm", { "placeholder": "00:00" });
    }
}
maskTimePicker();

// Check drag&drop support (fix Modernizr problem)
function isDragAndDropSupported() {
    var div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && !!window.FileReader;
}

// fix address bar IOS
function getIOSAddressBarHeight() {
    var iOSSafariAddressBarHeight = 0;

    if (isMobile.iOS()) {
        if (getIOSVersion() >= 6) {
            iOSSafariAddressBarHeight = 60;
        }

        if (getIOSVersion() >= 7) {
            if (isMobile.iPhone()) {
                iOSSafariAddressBarHeight = 69;
            }

            if (isMobile.iPad()) {
                iOSSafariAddressBarHeight = 57;
            }
        }
    }    

    return iOSSafariAddressBarHeight;
}

/*
 * String.format
 * Es:
 * var str = "She {1} {0}{2} by the {0}{3}. {-1}^_^{-2}";
 * str = str.format(["sea", "sells", "shells", "shore"]);
 */
String.prototype.format = function (args) {
    var str = this;
    return str.replace(String.prototype.format.regex, function (item) {
        var intVal = parseInt(item.substring(1, item.length - 1));
        var replace;
        if (intVal >= 0) {
            replace = args[intVal];
        } else if (intVal === -1) {
            replace = "{";
        } else if (intVal === -2) {
            replace = "}";
        } else {
            replace = "";
        }
        return replace;
    });
};
String.prototype.format.regex = new RegExp("{-?[0-9]+}", "g");

// Is JSON
function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

/*
 * Internationalization base method
 * Questo serve di base per recuperare le localizzazioni e gestirle.
 * Solo con questo puoi localizzare solo lato JS e non HTML.
 */
var Localization = {
    _options: null,
    _dictionary: null,
    _getBrowserLang: function () {
        var self = this;

        // Prelevo la lingua del browser
        var lang = navigator.language || navigator.userLanguage;

        if (!lang) {
            lang = self._options.fallbackLang;
        }
        lang = lang.toLowerCase();

        // Prelevo le prime 2 lettere della lang (Es. se ottengo 'en-US' prelevo solo 'en')
        if (self._options.useSubculture && lang.length > 2) {
            lang = lang.substring(0, 2);
        }

        return lang;
    },
    _getLocsJSON: function (_url) {
        var self = this;

        $.ajax({
            method: 'GET',
            url: _url,
            cache: false,
            async: false,
            contentType: 'application/json; charset=UTF-8',
            success: function (ret) {
                self._dictionary = ret;
            },
            error: function (err) {
                log('Localization: error retrieving locs for ' + self._options.lang + ' lang! Trying fallback lang...');
                self._dictionary = null;

                if (self._options.lang != self._options.fallbackLang) {
                    var _fallbackUrl = self._options.url.format([self._options.fallbackLang]);
                    self._getLocsJSON(_fallbackUrl);
                }
            }
        });
    },

    init: function (options) {
        var self = this;

        options = options || {};
        // Default settings
        var defaults = {
            url: '',
            lang: '',
            useSubculture: true, //LANG FORMAT: culture-subculture. Questa stringa è chiamata LOCALE.
            fallbackLang: 'it-IT'
        };
        self._options = $.extend(defaults, options);

        // Required
        if (!self._options.url)
            return;

        if (!self._options.lang) {
            self._options.lang = self._getBrowserLang();
        }

        if (jQuery.i18n) {

            // Verifico se dentro "url" c'è un JSON o un URL            
            if (isJson(self._options.url)) {
                self._dictionary = jQuery.parseJSON(self._options.url);;
            }
            else {
                // Fai lo STRING.FORMAT dell'url passata
                var url = self._options.url.format([self._options.lang]);

                // Ora prelevo la localizzazione dal json giusto (in base alla lingua) tramite una http get                    
                self._getLocsJSON(url);
            }
            
            // Localizzo     
            if (self._dictionary) {
                jQuery.i18n.load(self._dictionary);
            }

        }
        else {
            log('Missing $.i18n plugin');
        }

        return self;
    }
};
// Internationalization shortcut
function _(locID) {
    // Es:
    // jQuery.i18n._(locID);
    // jQuery.i18n._(locID, 100, 200); for wildcard example
    if (arguments.length > 1) {
        var args = Array.prototype.slice.call(arguments, 1);
        return jQuery.i18n._(locID, args);
    }

    return jQuery.i18n._(locID);
}


// is Retina test
function isRetina() {
    var mediaQuery = '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)';

    if (window.devicePixelRatio > 1) {
        return true;
    }

    if (window.matchMedia && window.matchMedia(mediaQuery).matches) {
        return true;
    }

    return false;
}

/* 
Define & init: data-toggle="group-image-change"
****************************/
$('[data-toggle="group-image-change"]').change(function(e){
    e && e.preventDefault();
    var $this = $(e.target), $class, $target;
    !$this.data('toggle') && ($this = $this.closest('[data-toggle="group-image-change"]'));         
    if ($this.is(':checked')) {
        //var $selected_input = $('input[name="' + $this.attr('name') + '"]:checked');
        $target = $($this.data('target') || $this.attr('href'));                
        $target.attr('src', $this.data('image'));
    }
}).change();

/* 
Define & init: data-toggle="radio-label-switch"
****************************/
$('[data-toggle="radio-label-switch"]').each(function(){
    var $this = $(this), $inputs, checked_text, unchecked_text;         
    checked_text = $this.data('checked-label');
    unchecked_text = $this.data('unchecked-label');
    $inputs = $('input[type="radio"]');
    //when one of the radio in the group is checked
    //update all of input in its group
    $inputs.change(function(e){
        var $changed_radio = $(e.target);
 
        //set uncheked_text for all radio input in the group
        $inputs.each(function(){
            $(this).next('.label-text').html('<span></span>' + unchecked_text);
        });

        //set checked_text just for the checked radio input
        if ($changed_radio.is(':checked')) {
            $changed_radio.next('.label-text').html('<span></span>' + checked_text);
        }

    }).filter('[checked="checked"]').change();
});

/* 
Define & init: data-toggle="select-all"
****************************/
$(document).on('click', '[data-toggle="select-all"]', function(e){
    e && e.preventDefault();
    var $this = $(e.target), $target;
    $target = $($this.data('target') || $this.attr('href'));
    $target.find('input[type="checkbox"]').prop('checked', true);
});

/* 
Define & init: data-toggle="deselect-all"
****************************/
$(document).on('click', '[data-toggle="deselect-all"]', function(e){
    e && e.preventDefault();
    var $this = $(e.target), $target;
    $target = $($this.data('target') || $this.attr('href'));
    $target.find('input[type="checkbox"]').prop('checked', false);
});

/* 
Change text on collapse: data-collapsed-text="Expand"
****************************/
var $data_collapsed_text = $('[data-collapsed-text]');
$(document).on('hide.bs.collapse', function(e) {
    var target_id, $triggers;
    target_id = $(e.target).attr('id');
    $triggers = $data_collapsed_text.filter('[data-target="#' + target_id + '"], [href="#' + target_id + '"]');             
    $triggers.each(function(){
        $(this).html($(this).data('collapsed-text'));
    });
});

/* 
Change text on uncollapse: data-uncollapsed-text="Minimize"
****************************/
var $data_uncollapsed_text = $('[data-uncollapsed-text]');
$(document).on('show.bs.collapse', function(e) {
    var target_id, $triggers;
    target_id = $(e.target).attr('id');
    $triggers = $data_collapsed_text.filter('[data-target="#' + target_id + '"], [href="#' + target_id + '"]');
    $triggers.each(function(){
        $(this).html($(this).data('uncollapsed-text'));
    });
});

// startsWith
if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

// endsWith
if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (searchString, position) {
        var subjectString = this.toString();
        if (position === undefined || position > subjectString.length) {
            position = subjectString.length;
        }
        position -= searchString.length;
        var lastIndex = subjectString.indexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
    };
}

// Querystring
function getQuerystringByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}